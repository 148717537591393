import { FormHelperText } from "@mui/material";
import Field from "controls/Field";
import Form, { FormSubmitButton } from "controls/Form";
import { useActionFragment } from "hooks/useAction";
import useEnforceAuth from "hooks/useEnforceAuth";
import useGotoUrl from "hooks/useGotoUrl";
import useShowMessage from "hooks/useShowMessage";
import TextInput from "inputs/TextInput";
import React from "react";

import PublicForm from "./PublicForm";

export default function RequestResetPasswordPage() {
  useEnforceAuth({ enforceUnauthenticated: true });
  const requestResetPassword = useActionFragment("requestResetPassword");
  const gotoUrl = useGotoUrl();
  const showMessage = useShowMessage();

  return (
    <PublicForm
      dense
      title="Reset Password"
      paperProps={{
        component: Form,
        onSubmit: async (formData) => {
          await requestResetPassword({
            input: {
              ...formData,
            },
          });
          await showMessage({
            header: "Reset Password",
            content:
              "An email will been sent to you with instructions on how to reset your password if an account exists with that email address.",
            modal: true,
          });
          await gotoUrl("/login");
        },
      }}
    >
      <p style={{ fontSize: "1.1em" }}>
        No problem, just enter your email address that you registered with below, and we&apos;ll send you a link to
        change your password.
      </p>
      <Field name="email" label="Email" input={<TextInput autoFocus autoComplete="email" type="email" />} required />
      <FormSubmitButton content="Send Email Instructions" />
      <FormHelperText>
        *your password won&apos;t change until you click on the link within the email, and create a new one.
      </FormHelperText>
      <FormHelperText>
        Already have the password? <a href="/login">back to login</a>
      </FormHelperText>
    </PublicForm>
  );
}
